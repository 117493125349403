import React, { createContext, useState, useEffect, useContext } from "react"


import fetch from "isomorphic-fetch"
import Client from "shopify-buy"
import { getID } from "../../tracking"

const client = Client.buildClient(
  {
    domain: process.env.GATSBY_SHOPIFY_STORE_URL,
    storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
  },
  fetch
)
///
const defaultValues = {
  cart: [],
  loading: false,
  addVariantToCart: () => { },
  addVaridantsToCart: () => { },
  removeLineItem: () => { },
  client,
  checkout: {
    id: "",
    lineItems: [],
    webUrl: ""
  },

}

const StoreContext = createContext(defaultValues)

const isBrowser = typeof window !== `undefined`
const localStorageKey = `shopify_checkout_id`

export const StoreProvider = ({ children }) => {


  
  const [cart, setCart] = useState(defaultValues.cart)
  const [checkout, setCheckout] = useState(defaultValues.checkout)
  const [loading, setLoading] = useState(false)

  

  const setCheckoutItem = (checkout) => {
    if (isBrowser) {
      localStorage.setItem(localStorageKey, checkout.id)
    }

    setCheckout(checkout)
  }

  useEffect(() => {
    initializeCheckout()
    
  }, [])

  


  const initializeCheckout = async () => {
    //check if there is an existing checkout
    const existingCheckoutID = isBrowser? localStorage.getItem(localStorageKey) : null

    //check if previous checkout has been completed if there is an existing checkout
    if(existingCheckoutID !== null) {
      try{
        const checkout = await client.checkout.fetch(existingCheckoutID)
       
        //if the checkout is complete, clear the cart and any stored url discounts
        if(checkout.completedAt !== null) {
          localStorage.setItem('cart', JSON.stringify([]))
          setCart([])
          localStorage.setItem("urlDiscount", null)

          //create completed checkout item
          const completedCheckout = {
            id: checkout.id,
            lineItems: checkout.lineItems,
            orderStatusUrl: checkout.orderStatusUrl,
          }
          //add a completed checkout to the local storage
          localStorage.setItem('completedCheckout', JSON.stringify(completedCheckout))
          //then create a new checkout
          let newCheckout = await client.checkout.create()
          setCheckoutItem(newCheckout)
        }else{
          //if the checkout is not complete, create a new checkout and set the cart to the items in the checkout
          //create a new checkout
          let newCheckout = await client.checkout.create()
          //get stored cart from local storage
          const storedCart = JSON.parse(localStorage.getItem('cart'))
          if(storedCart !== null){
            for(const cartItem of storedCart){
              const checkoutID = newCheckout.id
              const variantId = cartItem.product.variants[0]?.shopifyId
              const parsedQuantity = parseInt(cartItem.quantity, 10)
  
              const lineItemsToUpdate = [
                {
                  variantId,
                  quantity: parsedQuantity,
                },
              ]
              newCheckout = await client.checkout.addLineItems(checkoutID, lineItemsToUpdate)
            }
            setCart(storedCart)

          }
          setCheckoutItem(newCheckout)
        
        }
      }catch(e){
        console.log(e)
      }
    }else{
      //create a new checkout
     
      let newCheckout = await client.checkout.create()
      setCheckoutItem(newCheckout)
    }


   
    


  }

  const addVariantToCart = async (product, quantity) => {
    setLoading(true)

    if (checkout.id === "") {
      console.error("No checkout ID assigned.")
      return
    }

    const checkoutID = checkout.id
    const variantId = product.variants[0]?.shopifyId
    const parsedQuantity = parseInt(quantity, 10)

    const lineItemsToUpdate = [
      {
        variantId,
        quantity: parsedQuantity,
      },
    ]

    try {
      try{
        const mixpanelID = await getID()
       
        const input = {customAttributes: [{key: "mixpanelID", value: mixpanelID}]}
        await client.checkout.updateAttributes(checkoutID, input )
      }catch(e){
        console.log('unable to add custom attributes because: ',e)
      }
      
      const res = await client.checkout.addLineItems(checkoutID, lineItemsToUpdate)
      setCheckout(res)

      let updatedCart = []
     
     
      if (cart.length > 0) {
        
        const itemIsInCart = cart.find((item) => item.product.variants[0]?.shopifyId === variantId)
        
        if (itemIsInCart) {
          const newProduct = {
            product: { ...itemIsInCart.product },
            quantity: (itemIsInCart.quantity + parsedQuantity)

          }
          const otherItems = cart.filter((item) => item.product.variants[0]?.shopifyId !== variantId)
          updatedCart = [...otherItems, newProduct]
        } else {
          
         
          updatedCart = cart.concat([{ product, quantity: parsedQuantity }])
        }
      } else {
        
        updatedCart = [{ product, quantity: parsedQuantity }]
      }
      setCart(updatedCart)
      localStorage.setItem('cart',JSON.stringify(updatedCart))
      setLoading(false)
     
      return 'complete'
    } catch (error) {
      setLoading(false)
      console.error(`Error in addVariantToCart: ${error}`)
    }
    
  }


  const addVariantsToCart = async (lineItemsToUpdate, products) => {
    setLoading(true)

    if (checkout.id === "") {
      console.error("No checkout ID assigned.")
      return
    }

    const checkoutID = checkout.id
    
    

    

    try {
      try{
        const mixpanelID = await getID()
        const input = {customAttributes: [{key: "mixpanelID", value: mixpanelID}]}
        await client.checkout.updateAttributes(checkoutID, input )
      }catch(e){
        console.log('unable to add custom attributes because: ',e)
      }
      
      // add all line items to cart
      try{
        
        const res = await client.checkout.addLineItems(checkoutID, lineItemsToUpdate)
        setCheckout(res)
       
        let cartIds = res.lineItems[0].variableValues.lineItems
        
        let newCart = []
        let currentCart = JSON.parse(localStorage.getItem('cart'))
        if(currentCart !== null){
          cartIds.forEach((item) => {
            Object.keys(products).forEach((product) => {
              if(products[product].variants[0]?.shopifyId === item.variantId){
                const newProduct = {
                  product: { ...products[product]},
                  quantity: item.quantity //+ currentCart.find((cartItem) => cartItem.product.variants[0]?.shopifyId === item.variantId)?.quantity
                }
                newCart.push(newProduct)
              }
            })
            
          })

         
          let updatedCart = {}
          let concatCart = newCart.concat(currentCart)

          concatCart.forEach((item) => {
            if(updatedCart[item.product.id] === undefined){
              updatedCart[item.product.id] = {
                product: { ...item.product },
                quantity: item.quantity
              }
            }else if(updatedCart[item.product.id] !== undefined){
              updatedCart[item.product.id].quantity += item.quantity
            }
          })
          newCart = Object.keys(updatedCart).map((key) => updatedCart[key])
        }else{
          cartIds.forEach((item) => {
            Object.keys(products).forEach((product) => {
              if(products[product].variants[0]?.shopifyId === item.variantId){
                const newProduct = {
                  product: { ...products[product]},
                  quantity: item.quantity
                }
                newCart.push(newProduct)
              }
            })
          
          })
        }
        
        
        setCart(newCart)
        localStorage.setItem('cart',JSON.stringify(newCart))

      }catch(e){
        console.log('error adding line items',e)
      }
      
      //then access the current checkout object

      //use the checkout object to update the cart
      /*
      setCart(updatedCart)
      localStorage.setItem('cart',JSON.stringify(updatedCart))
      */
      setLoading(false)

    }catch(e){
      console.log(e)
    }

      
    
  }

  const removeLineItem = async (title) => {
    setLoading(true)
    
    try {
      if (checkout.lineItems.length < 1) throw new Error("Cart is empty")
      
      let lineItemID = ''
      
      checkout.lineItems?.forEach((item) => {
      
        if (item.title === title) {
          lineItemID = item.id
        }
      })

      if (!lineItemID) {
        
        return
      }

      const res = await client.checkout.removeLineItems(checkout.id, [lineItemID])
      setCheckout(res)

      const updatedCart = cart.filter((item) => item.product.title !== title)
      setCart(updatedCart)
      localStorage.setItem('cart',JSON.stringify(updatedCart))
      setLoading(false)
      
    } catch (error) {
      setLoading(false)
      console.error(`Error in removeLineItem: ${error}`)
    }
  }

  const addDiscount = async (code) => {
    if (code === "") return {discountAdded: false, error: "No code entered"}
    try{
      const res = await client.checkout.addDiscount(checkout.id, code)
      setCheckout(res)
      if(res.discountApplications.length > 0){
        return {discountAdded: true, error: ""}
      }else{
        return {discontAdded: false, error:"Invalid Code"}
      }
      
    }catch{
      return {discountAdded: false, error: "Something went wrong... Please try again later"}
    }
    
  }


  

  const removeDiscount = async () => {

    const res = await client.checkout.removeDiscount(checkout.id)
    setCheckout(res)

  }

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addVariantToCart,
        addVariantsToCart,
        removeLineItem,
        addDiscount,
        removeDiscount,
        cart,
        checkout,
        loading,

      }}
    >
      {children}
    </StoreContext.Provider>
  )
}

const useStore = () => {
  const context = useContext(StoreContext)

  if (context === undefined) {
    throw new Error("useStore must be used within StoreContext")
  }

  return context
}

export default useStore
