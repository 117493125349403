
const mixpanel = require('mixpanel-browser')


module.exports = {
    track: async (eventName, props) =>{

        let env= process.env.NODE_ENV 
        let prod = env === 'production'
    
        let eName
        if (!prod){
            eName = eventName + '-dev'
        }else if(prod){
            eName = eventName
        }

        try{
           
            mixpanel.track(eName, props)
        }catch(e){
            console.log(e)
            await mixpanel.init('381009f91a8368be593ef2a16c2aa0ef', {
                debug: false,
                ignore_dnt:true,
                loaded: function(){
                  mixpanel.track(eName, props)
                }
            });
        }
    },
    identify: async () =>{
        let env= process.env.NODE_ENV 
        let prod = env === 'production'
        if(prod){
            try{
                
                mixpanel.identify()
            }catch(e){
                console.log(e)
                await mixpanel.init('381009f91a8368be593ef2a16c2aa0ef', {
                    debug: false,
                    ignore_dnt:true,
                    loaded: function(){
                      mixpanel.identify()
                    }
                });
            }
        }
    },
    getID: async () =>{
        
       
        try{
           
            let mixpanelID = await mixpanel.get_distinct_id()
            return mixpanelID
        }catch(e){
            console.log(e)
            let mixpanelID = await mixpanel.init('381009f91a8368be593ef2a16c2aa0ef', {
                debug: false,
                ignore_dnt:true,
                loaded: function(){
                    let mixpanelID = mixpanel.get_distinct_id()
                    return mixpanelID
                }
            });
            return mixpanelID
        }
        
    }
}
