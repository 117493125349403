exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-affiliate-js": () => import("./../../../src/pages/affiliate.js" /* webpackChunkName: "component---src-pages-affiliate-js" */),
  "component---src-pages-before-drinking-hangover-prevention-js": () => import("./../../../src/pages/before-drinking-hangover-prevention.js" /* webpackChunkName: "component---src-pages-before-drinking-hangover-prevention-js" */),
  "component---src-pages-calc-test-js": () => import("./../../../src/pages/calc-test.js" /* webpackChunkName: "component---src-pages-calc-test-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-confirm-number-js": () => import("./../../../src/pages/confirm-number.js" /* webpackChunkName: "component---src-pages-confirm-number-js" */),
  "component---src-pages-electrolyte-powder-drink-that-prevents-hangovers-js": () => import("./../../../src/pages/electrolyte-powder-drink-that-prevents-hangovers.js" /* webpackChunkName: "component---src-pages-electrolyte-powder-drink-that-prevents-hangovers-js" */),
  "component---src-pages-electrolyte-powders-js": () => import("./../../../src/pages/electrolyte-powders.js" /* webpackChunkName: "component---src-pages-electrolyte-powders-js" */),
  "component---src-pages-electrolytes-for-hangover-prevention-js": () => import("./../../../src/pages/electrolytes-for-hangover-prevention.js" /* webpackChunkName: "component---src-pages-electrolytes-for-hangover-prevention-js" */),
  "component---src-pages-hangover-prevention-js": () => import("./../../../src/pages/hangover-prevention.js" /* webpackChunkName: "component---src-pages-hangover-prevention-js" */),
  "component---src-pages-ig-offer-js": () => import("./../../../src/pages/ig-offer.js" /* webpackChunkName: "component---src-pages-ig-offer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-the-science-js": () => import("./../../../src/pages/the-science.js" /* webpackChunkName: "component---src-pages-the-science-js" */),
  "component---src-pages-tiktok-offer-js": () => import("./../../../src/pages/tiktok-offer.js" /* webpackChunkName: "component---src-pages-tiktok-offer-js" */),
  "component---src-pages-yt-offer-js": () => import("./../../../src/pages/yt-offer.js" /* webpackChunkName: "component---src-pages-yt-offer-js" */),
  "component---src-templates-basic-page-js": () => import("./../../../src/templates/basicPage.js" /* webpackChunkName: "component---src-templates-basic-page-js" */)
}

